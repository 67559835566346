.Button {
  padding: 10px 24px;
  margin-top: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: white;
  border-radius: 6px;
  background-color: black;
  border: 1px solid black;
  cursor: pointer;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.85;
  }

  // &:active {
  //   transform: translateY(2px);
  // }
}

.White {
  color: black;
  border: 1px solid black;
  background-color: white;
}

.Blue {
  background-color: #2f8fff;
  border: 1px solid #2f8fff;
  color: white;
}

.BlueInverted {
  background-color: white;
  border: 1px solid #2f8fff;
  color: #2f8fff;
}

.Disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.ClickDisabled {
  pointer-events: none;
}

.Fit {
  width: fit-content;
}

.Rounded {
  border-radius: 50px;
}

.Small{
  padding: 10px 18px;
  font-size:12px;
  line-height: 100%;
}

.Tiny{
  padding: 6px 12px;
  font-size:10px;
  line-height: 110%;
}