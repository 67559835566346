.Login {
  background-color: #F0F0F0;
  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;


  .FormContainer {
    padding: 30px;
    width: 500Px;
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .Title {
      font-weight: 500;
      margin-bottom: 40px;
      font-size: 18px;
    }
  }
}