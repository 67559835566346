.ExistingBuyers{
    .Title{
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 10px;
        // border-bottom: 1px solid #e5e5e5;
    }
}

.Buyer{
    margin-top: 10px;
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 10px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    &:hover{
        border: 1px solid #d4d4d4;
    }

    .Company{
        font-size: 14px;
        font-weight: 500;
    }
    .Person{
        font-size: 10px;
        margin-top: 5px;
    }
    .Contact{
        font-size: 12px;

        display: flex;
        align-items: center;
        column-gap: 5px;
    }

    .CheckImg{
        position: absolute;
        right: 10px;
    bottom: 10px;
    }
}

.SelectedBuyer {
    border: 1px solid black;
    &:hover{
        border: 1px solid black;
    }
}