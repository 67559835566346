.ViewStyles {
  margin: 24px;

  .Filters {
    font-size: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }


}