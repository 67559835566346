.BuyerDetails{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 16px;

    .Section{
    border: 1px solid #e5e5e5;
    padding: 10px;

        .Title{
            font-size: 13px;
            color: #233299;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            position: relative;
            &>img{
                cursor: pointer;
            }
        }

        .Btn{
            position: absolute;
            top: 0;
            right: 0;
        }

        .Info{
            color: black;
            margin-top: 5px;
            font-size: 12px;
            font-weight: 400;
        }

        .InfoDouble{
            color: black;
            margin-top: 5px;
            font-size: 12px;
            font-weight: 400;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 20px;
        }
             .Bold{
            font-weight: 500;
        }
    }
}