.Table {
  margin-top: 20px;
  border: 1px solid rgba(137, 137, 137, 0.317);
  border-radius: 8px 8px 0 0;
}

.SingleStyle {
  border-bottom: 1px solid #e5e5e5;
  padding: 8px 15px;
  font-size: 12px;

  display: grid;
  grid-template-columns: 1.2fr 1fr 0.5fr 1fr 1fr 0.5fr;
  font-weight: 500;

  &>div {
    margin: 0 5px;
  }
}

.Header {
  background-color: black;
  color: white;
  border-radius: 4px 4px 0 0;
  padding: 15px;
}

.Badges {
  display: flex;
  column-gap: 5px;
  row-gap: 5px;
  flex-wrap: wrap;
  font-size: 10px;
  font-weight: 400;

  .Badge {
    background-color: #e5e5e5;
    height: fit-content;
    padding: 5px 5px 6px 5px;
    border-radius: 4px;
    width: fit-content;
    display: flex;
    align-items: center;
  }
}