.Sidebar {
  width: 300px;
  border-right: 1px solid #e5e5e5;
  padding: 16px;
  height: 100%;
}

.Option {
  color: #18181B;
  font-size: 12px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.Selected {
  background-color: #d8d8d8;

  &:hover {
    text-decoration: none;
  }
}