.EditStyles {
  display: flex;
  flex-direction: row;
  height: 100%;

  .MainSection {
    flex: 1 1 0;
    padding: 24px;
  }

  .Right {
    padding: 24px;
    height: 100%;
    width: 500px;
    border-left: 1px solid #e5e5e5;
  }
}

.Back {
  display: flex;
  align-items: center;
  font-size: 12px;
  width: fit-content;
  padding: 5px 10px 5px 0;
  border-radius: 4px;
  cursor: pointer;
}

.DoubleRow {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.DoubleRow3To1 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: flex-start;
  column-gap: 40px;
}

.TripleRow {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: flex-start;
  column-gap: 40px;
}

.Card {
  margin-top: 10px;
  border-radius: 8px;
  background-color: #f7f7f7;
  border: 1px solid #e5e5e5;
  padding: 16px;
  // height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InactiveCard {
  background-color: #e5e5e5;
  min-height: 74px;

  cursor: pointer;

  &>div:hover {
    transition: all 100ms ease-in-out;
  }
}

.Images {
  margin-top: 20px;
  display: flex;
  align-items: center;
  column-gap: 20px;
}