.Home {
  .Top {
    padding: 24px;
    border-bottom: 1px solid #e5e5e5;
    height: 100px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .Subtext {
      color: #000000bd;
      font-size: 14px;

      .email {
        color: #b91680;
        font-weight: 600;
      }
    }
  }

  .MainApp {
    display: flex;
    align-items: flex-start;
    height: calc(100vh - 100px);

    .Body {
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }

    // .RightPanel {
    //   padding: 16px;
    //   height: 100%;
    //   width: 500px;
    //   border-left: 1px solid #e5e5e5;
    // }
  }
}