.ModalContainer {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;

  .Tiny {
    height: 200px;
    width: 500px;
  }

  .Small {
    height: 500px;
    width: 500px;
  }

  .Long {
    height: 700px;
    width: 500px;
  }

  .Flexible {
    height: fit-content;
    width: fit-content;
    padding: 0 !important;
  }

  .Large {
    height: 600px;
    width: 800px;
  }

  .Full {
    height: 90vh;
    width: 94vw;
    overflow-y: scroll;
  }

  .Modal {
    position: relative;
    border-radius: 8px;
    background: white;
    padding: 15px;

    .CloseIcon {
      position: absolute;
      cursor: pointer;
      right: 15px;
      top: 15px;
      height: 12px;
      width: 12px;

      &:hover {
        fill: black;
        color: black;
        stroke: black;
        transform: scale(1.1);
      }
    }

    .Body {
      height: 100%;
      width: 100%;
    }
  }
}