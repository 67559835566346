.Lookbooks {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 100px);
      .Title {
        font-size: 16px;
        text-align: center;
      }
    
      .Left{
        flex: 1 1 0;
      }
    
      .Right{
        height: 100%;
        padding: 24px;
        height: 100%;
        width: 500px;
        border-left: 1px solid #e5e5e5;
      }
    }