.SearchContainer {
  position: relative;
  margin-top: 40px;
}

.ContainerWithErrorMsg {
  margin-top: 35px;
}

.FormInput {
  padding: 12px 12px 12px 35px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  border: none;
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  border-radius: 30px;
  background-color: #f9f9f9;
  border: 1px solid #bebebe;

  &:disabled {
    background-color: #f8fff8;
  }
}

.SearchIcon {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  height: 50%;
}

.Invalid {
  border: 1px solid #ce121d;
}

.Error {
  position: absolute;
  left: 0;
  color: #ce121d;
  top: calc(100% + 2px);
  font-size: 10px;
}