.Images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 10px;
}

.ImgCard {
  position: relative;
  border: 1px dashed #c7c7c7;
  height: 130px;
  width: 130px;
  background-color: #e5e5e5;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    border: 1px dashed #979797;

    &>img {
      transition: all 10ms ease-in-out;
      transform: scale(1.1);
    }
  }

  .Delete{
    position: absolute;
    top: 0;
    right: 0;
  }
}

.ImgUploaded{
  &:hover {
    border: 1px dashed #c7c7c7;

    &>img {
      transition: all 10ms ease-in-out;
      transform: none;
    }
  }

  .Delete{
    position: absolute;
    top: 5px;
    right: 5px;

    &:hover {
      transition: all 10ms ease-in-out;
      transform: scale(1.1);
    }
  }
}

.ProgressContainer {
  height: 6px;
  position: relative;
  width: 100%;
  border: 1px solid red;
  border-radius: 10px;
  background-color: #f7f7f7;
  border: 1px solid #e5e5e5;
  margin-top: 5px;
  overflow: hidden;

  .ProgressBar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: rgb(0, 140, 210);
    border-radius: 10px;
    transition: all 200ms ease-in-out;
  }
}

.LoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #97979724;
}

.FileName {
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 10px;
  margin-top: 5px;
  width: 90%;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  text-decoration: underline;
}

.FileInput {
  position: absolute;
  left: 0;
  top: 0;
  height: 0px;
  width: 0px;
  visibility: hidden;
}

.Notice {
  width: 50%;
  background-color: rgb(233, 65, 31);
  color: white;
  padding: 16px;
  border-radius: 8px;
}