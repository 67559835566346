.RightSection {
  font-size: 12px;
}

.Top {
  min-height: 300px;
  border-bottom: 1px solid #e5e5e5;
}

.Section {
  margin-bottom: 20px;
  font-weight: 500;
}

.StyleImages{
  height: 70px;
  margin-top: 10px;
  &>img{
  height: 100%;
  margin-right: 10px;
  }
}

.Select {
  margin-top: 10px;
  font-weight: 400;
}

.SuccessMsg {
  font-weight: 600;
  display: flex;
  align-items: center;
  column-gap: 20px;
  height: 39px;
}