/* Table Spin Loader css starts */
#loading-spinner {
  position: relative;
  margin: 0 0 2px 10px;
}

#loading-spinner .spin-icon {
  margin: auto;
  width: 15px;
  height: 15px;

  border: solid 3px transparent;
  border-top-color: white;
  border-left-color: white;
  border-radius: 80px;

  -webkit-animation: loading-spinner 500ms linear infinite;
  -moz-animation: loading-spinner 500ms linear infinite;
  -ms-animation: loading-spinner 500ms linear infinite;
  -o-animation: loading-spinner 500ms linear infinite;
  animation: loading-spinner 500ms linear infinite;
}

@keyframes loading-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes loading-spinner {
  0% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}