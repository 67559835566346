.OrderDetails{
    height: 100%;

    display: flex;
    flex-direction: row;

    .LeftSection{
        flex: 1 1 0;
        padding: 16px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
          }
          & {
            -ms-overflow-style: none;  
            scrollbar-width: none;  
          }
    }
    .RightSection{
        padding: 16px;
        width: 400px;
        border-left: 1px solid #e5e5e5;
    }
}
.SingleItem {
    position: relative;
    padding: 5px;
    margin-top: 10px;
    border: 1px dashed #e5e5e5;
    display: flex;
    align-items: center;
    min-height: 88px;

    .Images{
        width: 150px;
        max-height: 80px;
        overflow: auto;

        & > img{
            margin-right: 5px;
        }
    }
    .Inputs{
        display: flex;
        column-gap: 10px;
        margin-top: 10px;
    }
    .Select{
        font-size: 10px;
        width: 350px;
    }

    .StyleData{
        font-size: 10px;
        display: flex;
        column-gap: 20px;
        margin-left: 20px;

        .Data{
            width: 65px;
        }

        .Item{
            margin-top: 5px;

        }
        .Bold{
            font-weight: 600;
        }

    }

    .UpchargeBox{
        width: 100px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px;
        margin-left: 20px;
        &>div{
            display: grid;
            grid-template-columns: 1fr 1fr;
            font-size: 8px;
        }

        .UpchargeLabel{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
        }
    }
    .Delete{
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }
}