// @import '../../../scss/animations';

.DrawerBackdrop {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: flex-end;
    backdrop-filter: blur(6px);
    animation: fadeIn 100ms ease-in-out;
}

.DrawerBody {
    background: white;
    width: 90vw;
    height: 100%;
    position: relative;
    animation: moveIn 350ms;
    animation-timing-function: cubic-bezier();

    position: relative;
}

.closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
}

.DrawerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 16px;
    border-bottom: 1px solid #dcdcdc;

    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.000559105px;
    color: #233299;

    & > img {
        cursor: pointer;

        &:hover {
            transform: scale(1.1);
        }
    }
}

.CloseDrawer {
    animation: moveOut 350ms;
    animation-timing-function: cubic-bezier();
}

@keyframes moveIn {
    0% {
        transform: translateX(90vw);
    }
    25% {
        transform: translateX(90vw);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes moveOut {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(90vw);
    }
}

@keyframes fadeIn {
    0% {
        backdrop-filter: blur(0px);
    }
    100% {
        backdrop-filter: blur(6px);
    }
}
