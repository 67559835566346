body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  // font-family: 'Gilroy-Medium';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f7f7;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

* {
  box-sizing: border-box;
}

@font-face {
  font-family: champagne;
  src: url(../assets/fonts/champage-cyrillic.ttf);
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Medium.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Regular.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy'), url(../assets/fonts/Gilroy-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy'), url(../assets/fonts/Gilroy-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: local('Gilroy'), url(../assets/fonts/Gilroy-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}

// input[type='text'],
// input[type='email'] {
//   font-family: 'Gilroy-Medium';
//   /* border: 0.5px solid #ebebeb; */
//   border: 1px solid #ced4da;
//   border-radius: 4px;
//   /* color: #878787; */
//   font-weight: 300;
//   font-size: 12px;
//   letter-spacing: 0;
//   line-height: 10px;
//   padding: 10px;
//   width: 100%;
//   max-width: 100%;
//   outline: none;

//   &:focus {
//     color: #000000;
//     border: 1px solid #424242;
//     outline: none;
//   }
// }

a {
  text-decoration: none;
  // color: #000000;
}

textarea {
  resize: none;
}

// @keyframes fadein {
//   0%{
//     opacity: 0;
//   }
//   100%{
//     opacity: 1;
//   }
// }

.recharts-legend-item-text {
  color: black !important;
}