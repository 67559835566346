.InputContainer {
  position: relative;
  display: inline-block;
  // margin-top: 40px;
}

.ContainerWithErrorMsg {
  margin-top: 35px;
}

.FormInput {
  padding: 5px 0 2px 0px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  border: none;
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  background-color: white;
  border-bottom: 1px solid #d0d0d0;

  &:-webkit-autofill+label {
    top: -15px;
    left: 0;
    font-size: 10px;
    color: #828282;
    font-weight: 300;
  }

  // &:focus {
  //   // border: 1px solid black;
  // }

  &:disabled {
    background-color: transparent;
    border-bottom: 1px solid transparent;
    // cursor: not-allowed;
  }
}

.BorderlessDisbaled{
  &:disabled {
    background-color: transparent;
    border: none;
    cursor: not-allowed;
    padding-left: 0;
  }
}

.Invalid {
  border: 1px solid #ce121d;
}

.PrefillInput {
  padding-left: 60px;
}

.SmallInput{
  padding: 10px;
  font-size: 10px;
  &:-webkit-autofill+label {
    top: -15px;
    left: 0;
    font-size: 8px;
    color: #828282;
    font-weight: 300;
  }
}

.TinyInput{
  padding: 12px;
  font-size: 12px;
  &:-webkit-autofill+label {
    top: -15px;
    left: 0;
    font-size: 10px;
    color: #828282;
    font-weight: 300;
  }
}

.FormLabel {
  position: absolute;
  pointer-events: none;
  left: 0px;
  transition: 0.5s;
  top: -25px;
  font-size: 14px;
  font-weight: 500;
  color: #4f4f4f;
  transition: 0.5s;
  margin-bottom: 0px;
}

.Prefill {
  position: absolute;
  left: -1.5px;
  top: 0;
  height: 100%;
  width: 50px;
  background-color: #e5e5e5;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 0 0 6px;
  border-right: 1px solid #cbcbcb;
}

.Error {
  position: absolute;
  left: 0;
  color: #ce121d;
  top: calc(100% + 2px);
  font-size: 10px;
}