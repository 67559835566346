.Sizes {
  display: flex;
  align-items: center;
  // grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
}

.SingleSize {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

.SizesInvoice{
  width: 280px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 10px;

  .SingleSize {
    display: grid;
    font-size: 8px;
    font-weight: 500;

  }
}